<template>
  <div class="vx-row">
    <div id="chart">
      <vue-apex-charts type="area" height="200" width="1550" :options="chartOptions" :series="series"></vue-apex-charts>
    </div>


  </div>
</template>


<script>

import ApiService from "../../../api/index";
import moment from 'moment';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import QueryHelper from "../../../mixins/helper/query.helper";
import * as qs from "qs";
import PriceHelper from "../../../mixins/helper/price.helper";
import vSelect from 'vue-select'
import staticOptions from "../../../mixins/static/options";
import {mapGetters} from "vuex";
import VueApexCharts from 'vue-apexcharts'

export default {
  name: "StatisticsHistory",
  components: {
    flatPickr,
    'v-select': vSelect,
    VueApexCharts
  },
  data() {
    return {
      showStatisticsHistory: true,
      settlements: null,

      /*
      Chart
      */
      series: [],
      chartOptions: {
        chart: {
          width: '100%',
          height: 250,
          type: 'area'
        },
        tooltip: {},
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        labels: ["2"],
        xaxis: {
          type: 'string',
          categories: [],

        },

      },

    }

  },
  async created() {
    let date = new Date();
    this.month = this.$route.params.month || moment().format('MM');
    this.year = this.$route.params.year || moment().format('YYYY');

    if (this.$route.params.userId) {
      this.fetchSettlements(true, this.$route.params.userId)
    }
  },
  computed: {
    ...mapGetters(['userId', 'workspace']),
    unitOptions() {
      return staticOptions.units;
    },

  },
  methods: {
    fetchSettlements() {
      ApiService.get(`commision_settlement/statistics-history/${this.$route.params.userId}?wage_period_year=${this.$route.params.year}`)
          .then(response => {
            if (response.error == undefined) {
              this.settlements = response.data.result;
              this.prepareChart()
              this.$vs.loading.close()
              return
            }
            this.settlements = null;
            this.$vs.loading.close()

          })
          .catch((error) => {
            this.$vs.loading.close();
            this.settlements = null;

            this.$vs.notify({
              title: 'Kein Provisionsabrechnungen gefunden',
              text: '',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          })

    },

    prepareChart() {
      if (!this.settlements) {
        return;
      }

      // Chart 1 - Monthly
      this.series = []
      this.chartOptions.labels = [];
      var obj = [];

      this.chartOptions.labels = this.settlements.labels
      this.series = this.settlements.data

      this.chartOptions = {
        labels: this.settlements.labels
      };


    },


  },
  mounted() {
  },
  watch: {}
}
</script>

<style lang="scss">


</style>